<template>
    <b-form @submit="onSubmit">
        <b-row class="text-left align-items-start">
            <b-col cols="12" lg="6">
                <div class="d-flex align-items-start mb-3">
                    <h4 class="font-weight-bold mr-1">1.</h4>
                    <div>
                        <h4 class="font-weight-bold">Indtast varenumre</h4>
                        <p class="mb-0">F.eks. 9842383</p>
                    </div>
                </div>
                <b-form-textarea placeholder="Tilføj et linjeskift for hvert produkt-id" v-model="listId" rows="16"
                    max-rows="16" required />

                <b-alert variant="danger" class="mt-5" show v-if="errorMessage" v-html="errorMessage">
                </b-alert>
            </b-col>
            <b-col cols="12" lg="6" class="mt-4 mt-lg-0">
                <div class="d-flex align-items-start mb-2">
                    <h4 class="font-weight-bold mr-1">2.</h4>
                    <div class="w-100">
                        <h4 class="font-weight-bold">Vælg skiltestørrelse</h4>
                        <p class="mb-0">Vælg størrelsen på skiltet</p>

                        <div class="d-flex flex-wrap">
                            <b-form-checkbox :required="!hasCheckedAnyOption" class="mt-2"
                                v-bind:class="{ 'mr-3': index < listSizes.length - 1 }" button-variant="size-option"
                                :key="index" v-model="item.checked" name="check-button" button
                                v-for="(item, index) in listSizes">
                                {{ item.listName ? item.listName : item.name }}
                            </b-form-checkbox>
                        </div>
                    </div>
                </div>

                <div class="d-flex align-items-start mb-2">
                    <h4 class="font-weight-bold mr-1">3.</h4>
                    <div class="w-100">
                        <h4 class="font-weight-bold d-inline-block">Udleveringssbon</h4>
                        <h5 class="ml-2 d-inline-block">(Valgfrit)</h5>

                        <b-form-checkbox v-model="deliveryReceipt"
                            @change="deliveryReceipt ? selectedCampaign = null : null">
                        </b-form-checkbox>
                    </div>
                </div>

                <div class="d-flex align-items-start mb-2">
                    <h4 class="font-weight-bold mr-1">4.</h4>
                    <div class="w-100">
                        <h4 class="font-weight-bold d-inline-block">Insert number</h4>
                        <h5 class="ml-2 d-inline-block">(Valgfrit)</h5>

                        <div class="w-100 d-flex align-items-center">
                            <b-form-input v-model="numberBox" type="number" min="1" step="1" placeholder="1"
                            class="w-25 d-block mt-2 mr-3" />

                            <b-form-checkbox v-model="sequentially" :disabled="!numberBox">
                                Sekventielt
                            </b-form-checkbox>
                        </div>
                    </div>
                </div>

                <div class="d-flex align-items-start mb-2">
                    <h4 class="font-weight-bold mr-1">5.</h4>
                    <div class="w-100">
                        <h4 class="font-weight-bold d-inline-block">Kampagnetype</h4>
                        <h5 class="ml-2 d-inline-block">(Valgfrit)</h5>

                        <b-form-select v-model="selectedCampaign" :disabled="deliveryReceipt"
                            :options="campaignTypeOptions" class="w-50 d-block mt-2" />
                    </div>
                </div>

                <div class="d-flex align-items-start mt-3">
                    <h4 class="font-weight-bold mr-1">6.</h4>
                    <div>
                        <h4 class="font-weight-bold">Indtast antal kopier</h4>
                        <p class="mb-0">Indtast antallet af kopier som du ønsker</p>
                        <b-form-input v-model="quantity" type="number" min="1" step="1" placeholder="1"
                            class="w-25 d-block mt-2" />
                    </div>
                </div>

                <div class="d-flex align-items-start mt-3">
                    <h4 class="font-weight-bold mr-1">7.</h4>
                    <div>
                        <h4 class="font-weight-bold">Priser</h4>
                        <p class="mb-0">Brug fremtidige priser fra inRiver</p>
                        <b-form-checkbox v-model="useInRiverPrices">
                        </b-form-checkbox>
                    </div>
                </div>

                <div class="d-flex align-items-start mt-3">
                    <h4 class="font-weight-bold mr-1">8.</h4>
                    <div>
                        <h4 class="font-weight-bold d-inline-block">E-mail</h4>
                        <h5 class="ml-2 d-inline-block">(Valgfrit)</h5>
                        <div class="w-100"></div>
                        <p class="mb-0">Udfyld email for at få skiltene tilsendt på email</p>
                        <b-form-input v-model="email" type="email" min="1" step="1" placeholder="example@email.com"
                            class="mt-2 w-100 d-block" />
                    </div>
                </div>

                <div class="w-100 align-items-center mt-5 d-flex flex-column">
                    <b-button type="submit" class="w-50" :disabled="disableButton">
                        Print
                    </b-button>
                </div>
            </b-col>
        </b-row>
    </b-form>
</template>

<script>
import pdfService from "@/services/pdfService";

export default {
    name: "InputDataImport",
    props: ["errorMessage"],
    data() {
        return {
            listId: "",
            quantity: null,
            numberBox: null,
            sequentially: false,
            email: "",
            useInRiverPrices: false,
            deliveryReceipt: false,
            listSizes: [{
                name: "A4",
                checked: false
            },
            {
                name: "A5",
                checked: false
            },
            {
                name: "A6",
                checked: false
            },
            {
                name: "ShelfSign",
                listName: "A7",
                checked: false
            },
            {
                name: "Jordskilt",
                checked: false
            }],
            disableButton: false,
            campaignTypeList: [],
            selectedCampaign: null
        }
    },
    computed: {
        hasCheckedAnyOption() {
            return this.$data.listSizes.filter(f => f.checked == true).length > 0;
        },
        objectForAPI() {
            let productIds = this.$data.listId.split("\n");
            productIds = productIds.filter(f => f);
            let signTypesChecked = this.$data.listSizes.filter(m => m.checked);

            let finalObject = {
                Email: this.email,
                SignDefinitions: [],
                UseInRiverPrices: this.useInRiverPrices,
                NumberBox: this.numberBox,
                Sequentially: this.sequentially,
                DeliveryReceipt: this.deliveryReceipt,
                CampaignType: this.selectedCampaign
            };

            productIds.forEach((item) => {

                if (item) {
                    signTypesChecked.forEach((sign) => {
                        finalObject.SignDefinitions.push({
                            ItemNumber: item.replace(/\s/g, ""),
                            SignType: sign.name,
                            Quantity: this.quantity ? this.quantity : 1
                        });
                    });
                }
            });

            return finalObject;
        },
        campaignTypeOptions() {
            if (!this.campaignTypeList) {
                return [];
            }

            const optionsArray = this.campaignTypeList.map(m => {
                return {
                    text: m.Label,
                    value: m.Key
                }
            });

            optionsArray.unshift({
                value: null,
                text: 'Vælg en fra listen'
            })

            return optionsArray;
        }
    },
    methods: {
        async onSubmit($event) {
            $event.preventDefault();

            this.disableButton = true;
            let response = await pdfService.generatePDFsWithInput(this.objectForAPI);
            this.disableButton = false;
            this.$emit("goToProgress", { statusUri: response.statusQueryGetUri, milliseconds: 2000, showEmailStep: this.email ? true : false });
        },
        goBack() {
            this.$emit("reset");
        }
    },
    async mounted() {
        this.$data.campaignTypeList = await pdfService.getCVLValuesCampaingType();
    }
}
</script>